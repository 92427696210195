:root {
    --bg-color: #886080;
}
/****************HOMEPAGE*********************/
.start {
    animation: startText 0.75s;
    animation-delay: 0.3s;
    animation-fill-mode: backwards;
}
@keyframes startText {
    from {
        opacity: 0;
        padding-top: 5rem;
    }
    to {
        opacity: 1;
        padding-top: 0rem;
    }
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

.product-card {
    background-color: ghostwhite;
    color: #696969;
    transition: 0.3s;
}

.product-card:hover {
    background-color: #696969;
    color: ghostwhite;
}

/********************************************/
.dropdown-item {
    font-size: 0.94rem !important;
}

ul {
    text-align: left;   
}

li {
    text-align: left;
}

.link{
    color: inherit !important;
    text-decoration: none !important;
}
.App {
    text-align: center;
    display: flex;
}


.bg-img {
    background-image: linear-gradient(#12345600, #12345600, var(--bg-color)), url('./image/BG3.png');
    background-size: cover;
    background-position: top;
    background-attachment: scroll;
}

.bg-br {
    background-color: #C14953;
}

.bg-gw {
    background-color: #F0EFF4;
}

.bg-rb {
    background-color: #292929; 
}

.bg-tb {
    background-color: #00BFB3;
}

.bg-msb {
    background-color: #7F7EFF;
}

.bg-pp {
    background-color: var(--bg-color);
}

.padr-1 {
    padding-right: 1rem;
}
.padr-2 {
    padding-right: 2rem;
}
.padr-3 {
    padding-right: 3rem;
}
.padr-4 {
    padding-right: 4rem;
}
/****************FOOTER***********************/
.footer {
    height: 300px;
}



.footer-logo {
    height: 35%;
    justify-content: center;
}

.footer-media {
    padding-top: 1rem;
    height: 15%;
    justify-content: space-around;
    align-items: center ;
}
.footer-mail {
    height: 30%;
    justify-content: center;
}


.footer-bottom {
    height: 20%;
    color:#F0EFF4;
    padding-top: 1rem;
}

.intro {
    height: 110vh;
    width: 100%;
}

.nav-scrolled {
    background-color: var(--bg-color)/*#770f5e90*/;
    backdrop-filter: blur(15px);
    /* box-shadow: #3f3f3f 0px 0px 20px; */
}

.navbar-scrolled {
    color: #292929;
}

.nav-transition {
    transition-property: background-color, color, box-shadow ;
    transition-timing-function: ease;
    transition-duration: 0.5s;
}

.product-tab {
    line-height: 3rem;
    font-size: 1.2rem; 
    color: darkgray;
}
.product-tab .sub-tab {
    color:rgb(122, 122, 122) !important;
    padding-left: 1.5rem;
}
.template {
    color: #616161;
    box-shadow: 5px 5px 5px #7c7c7c;
    /* border-style: ; */
}

.animate {
    animation-duration: 0.3s;
    animation-fill-mode: both;
}

@keyframes fades {
    0% {
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: top;
    }
}

.fades {
    animation-name: fades;
}


/*                 MEDIA                */

.album-container > * {
    width: 13em !important;
    height: 13em !important;
    background-color: #F0EFF4;
    margin: 2em auto;
    border-radius: 1em;
    text-decoration: none;
    color: #696969;
    display: block;
    border: none;
    box-shadow: #292929 0px 0px 20px 0px;
}

.album-container * {
    transition: 0.3s;
}

.album-container *:hover {
    color: #F0EFF4;
    background-color: #696969;
}

.modal-content {
    border-radius: 1em !important;
    border-style: none;
    box-shadow: 0px 0px 20px 0px;
}